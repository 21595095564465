import React from "react";
import { Img } from "../../../Img";

import styles from "./Detail.module.css";

export interface IDetailProps {
  icon: string;
  title: string;
  value: string;
}

export const Detail: React.FC<IDetailProps> = ({ icon, title, value }) => {
  return (
    <div className={styles.root}>
      <div className={styles.titleWrapper}>
        <Img src={icon} />
        <p className={styles.title}>{title}</p>
      </div>
      <p className={styles.value}>{value}</p>
    </div>
  );
};
