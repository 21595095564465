import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Event from "../elements/EventCard";

import styles from "./EventsCarousel.module.css";
import { Courses } from "../../services/wordpressApi";

export interface IEventsCarouselProps {
  header: string;
  courses: Courses[];
}

export function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ transform: "rotate(180deg)" }}
      onClick={onClick}
    >
      <img src={require("./images/arrow.svg")} />
    </div>
  );
}

export function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={require("./images/arrow.svg")} />
    </div>
  );
}

export default function EventsCarousel(props: IEventsCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState<number>();

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (index: number) => setCurrentIndex(index),
    responsive: [
      {
        breakpoint: 1601,
        settings: {
          slidesToShow: 4,
          centerMode: true,
        },
      },
      {
        breakpoint: 1301,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  useEffect(() => {
    const allSlides = document.querySelectorAll(".slick-slide");
    const allActiveSlides = document.querySelectorAll(
      ".slick-slide.slick-active"
    );

    const visibleActiveSlides = [...allActiveSlides].slice(1, 4);
    [...allSlides].forEach((elem) => {
      elem.classList.remove(styles.visibleSlide);

      if (
        elem.classList.contains("slick-active") &&
        visibleActiveSlides.includes(elem)
      ) {
        elem.classList.add(styles.visibleSlide);
      }
    });
  }, [currentIndex]);

  return (
    <div className={styles.root}>
      <h2 className={styles.header}>{props.header}</h2>
      <Slider {...settings}>
        {props.courses.map((event) => (
          <div className={styles.eventWrapper}>
            <Event {...event} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
