import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    coursePrice: "Price",
    coursePriceAddInfo: "(in EUR)",
    registrationStatrs: "Registration starts:",
    checkDates: "Check available dates",
    bookingOpen: "Booking open",
    readMore: "Read more",
    level: "Level:",
    intensity: "Intensity:",
    duration: "Duration:",
    group: "Group:",
    materials: "Course materials:",
    blogPageNextPost: "Next entry",
    blogPagePreviousPost: "Previous entry",
    blogPageShareThisContent: "Share this content",
    blogPageNewerPosts: "Show newer articles",
    blogPageOlderPosts: "Show older articles",
    blogPageGoBack: "Go back",
  },
  [Languages.German]: {
    coursePrice: "Preis",
    coursePriceAddInfo: "(in EUR)",
    registrationStatrs: "Anmeldungsbeginn:",
    checkDates: "Kurstermine anzeigen",
    bookingOpen: "Buchung offen",
    readMore: "Weiterlesen",
    level: "Niveau:",
    intensity: "Intensität:",
    duration: "Dauer",
    group: "Gruppe:",
    materials: "Kursmaterial:",
    blogPageNextPost: "Nächster Eintrag",
    blogPagePreviousPost: "Vorherige Eintrag",
    blogPageShareThisContent: "Diese Inhalte teilen",
    blogPageNewerPosts: "Neuere Artikel ansehen",
    blogPageOlderPosts: "Ältere Artikel ansehen",
    blogPageGoBack: "Geh zurück",
  },
};

export { translations };
