import React, { useContext, useState } from "react";
import Button from "../Button";
import { Courses } from "../../../services/wordpressApi";
import { Detail } from "./elements/Detail/Detail";

import levelIcon from "./assets/levelIcon.svg";
import intesityIcon from "./assets/intesityIcon.svg";
import durationIcon from "./assets/durationIcon.svg";
import infoTooltipIcon from "./assets/infoTooltipIcon.svg";
import participantsIcon from "../../CourseInformation/assets/participantsIcon.svg";
import learningMaterialsIcon from "../../CourseInformation/assets/learningMaterialsIcon.svg";
import { Img } from "../Img";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Link from "next/link";
import { urlReplace } from "../../../services/urlReplace";
import LanguageContext from "../../LanguageContext";
import { translations } from "../translations";

import styles from "./EventCard.module.css";
import cx from "classnames";

export const EventCard: React.FC<Courses> = ({
  id,
  title,
  slug,
  forWho,
  level,
  intesity,
  duration,
  group,
  materials,
  price,
  additionalText,
  description,
}) => {
  const { language } = useContext(LanguageContext);
  const [isActive, setIsActive] = useState(true);
  const renderTooltip = (props: any) => (
    <Tooltip {...props}>{additionalText}</Tooltip>
  );

  return (
    <div className={cx("eventCard", styles.root)}>
      <div className={styles.topCard}>
        {isActive && (
          <div className={styles.isActiveWrapper}>
            <div className={styles.activeCircle}></div>
            <p className={styles.activeTitle}>
              {translations[language].bookingOpen}
            </p>
          </div>
        )}
        <h5 className={styles.title}>{title}</h5>
        <div className={styles.forWhoWrapper}>
          {forWho.map((who) => (
            <p>{who}</p>
          ))}
          {additionalText && (
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <Img src={infoTooltipIcon} />
            </OverlayTrigger>
          )}
        </div>
      </div>
      <div className={styles.centerCard}>
        <div className={styles.detailsWrapper}>
          <Detail
            icon={levelIcon}
            title={translations[language].level}
            value={level}
          />
          <Detail
            icon={intesityIcon}
            title={translations[language].intensity}
            value={intesity}
          />
          <Detail
            icon={durationIcon}
            title={translations[language].duration}
            value={duration}
          />
          {group && (
            <Detail
              icon={participantsIcon}
              title={translations[language].group}
              value={group}
            />
          )}
          {materials && (
            <Detail
              icon={learningMaterialsIcon}
              title={translations[language].materials}
              value={materials}
            />
          )}
        </div>
        <div className={styles.costWrapper}>
          <p className={styles.priceTitle}>
            {translations[language].coursePrice}{" "}
            {translations[language].coursePriceAddInfo}
          </p>
          <p className={styles.priceValue}>{price}</p>
          {additionalText && (
            <p className={styles.priceText}>{additionalText}</p>
          )}
        </div>
      </div>
      <div className={styles.bottomCard}>
        <p className={styles.description}>{description}</p>
        <div className={styles.buttonsWrapper}>
          <Link href={urlReplace(slug)} passHref>
            <a>
              <Button variant={"primary"}>
                {translations[language].checkDates}
              </Button>
            </a>
          </Link>
          <Link href={urlReplace(slug)} passHref>
            <a>
              <Button variant={"secondary"}>
                {translations[language].readMore}
              </Button>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};
